import { ErrorMessage, Formik } from 'formik';
import React from 'react'
import * as Yup from 'yup';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getAreaListData } from 'features/facility/areaListSlice';
import { addScheduleData } from 'features/schedule/addScheduleSlice';
import { editScheduleData } from 'features/schedule/editScheduleSlice';
import { addScheduleRemoveStateData } from 'features/schedule/addScheduleSlice';
import { editScheduleRemoveStateData } from 'features/schedule/editScheduleSlice';
import dayjs from 'dayjs';
import { getDeviceListData } from 'features/device/deviceListSlice';
import { getScheduleTableData } from 'features/schedule/scheduleTableSlice';
import { getProfileListData } from 'features/profile/profileListSlice';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import ReactDatetime from "react-datetime";

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
const ScheduleAddAndEdit = ({ open, toggle, isEdit, editSchedule, notify }) => {
    const areaList = useSelector((state) => state.areaList);
    const facilityList = useSelector((state) => state.facilityList);
    const deviceList = useSelector((state) => state.deviceList);
    const profileList = useSelector((state) => state.profileList);
    const EditSchedule = useSelector((state) => state.editSchedule);
    const addSchedule = useSelector((state) => state.addSchedule);
    const scheduleTable = useSelector((state) => state.scheduleTable);
    const dispatch = useDispatch();
    // const deviceList = [
    //     { value: "device", label: "device" },
    //     { value: "device 2", label: "device 2" },
    //     { value: "device 3", label: "device 3" },
    //     { value: "device 4", label: "device 4" },
    //     { value: "device 5", label: "device 5" },
    //     { value: "device 6", label: "device 6" },
    //     { value: "device 7", label: "device 7" },
    // ]
    // const profileList = [
    //     { value: "profile", label: "profile" },
    //     { value: "profile 2", label: "profile 2" },
    //     { value: "profile 3", label: "profile 3" },
    //     { value: "profile 4", label: "profile 4" },
    //     { value: "profile 5", label: "profile 5" },
    //     { value: "profile 6", label: "profile 6" },
    //     { value: "profile 7", label: "profile 7" },
    // ]

    const getAreaList = async (id) => {
        await dispatch(getAreaListData({ id: id }));
    }
    const getDeviceList = async (id) => {
        await dispatch(getDeviceListData({ facility_id: id }));
    }
    const getProfileList = async (id) => {
        await dispatch(getProfileListData({ user_device_id: id }));
    }

    // React.useEffect(() => {
    //     if(isEdit && editSchedule){
    //         getAreaList(editSchedule.facility_id);
    //     }
    // },[]);

    React.useEffect(() => {
        (async () => {
            if (isEdit) {
                if (EditSchedule.loading === 'succeeded') {
                    toggle();
                    dispatch(editScheduleRemoveStateData());
                    dispatch(getScheduleTableData({ page: scheduleTable.page_information.current_page }));
                } else if (EditSchedule.loading === 'failed') {
                    notify(EditSchedule?.error?.data?.message, true);
                }
                dispatch(editScheduleRemoveStateData());
            } else {
                if (addSchedule.loading === 'succeeded') {
                    toggle();
                    dispatch(addScheduleRemoveStateData());
                    dispatch(getScheduleTableData({ page: scheduleTable.page_information.current_page }));
                } else if (addSchedule.loading === 'failed') {
                    notify(addSchedule?.error?.data?.message, true);
                    dispatch(addScheduleRemoveStateData());
                }
            }
        })();
    }, [addSchedule.loading, EditSchedule.loading]);


    const initialFormValues = {
        facility: editSchedule ? { value: editSchedule?.facility_id, label: editSchedule?.facility_name } : null,
        area: editSchedule ? { value: editSchedule?.cultivation_area_id, label: editSchedule?.cultivation_area_name } : null,
        device: editSchedule ? { value: editSchedule?.user_device_id, label: editSchedule?.device_name } : null,
        profile: editSchedule ? { value: editSchedule?.profile_id, label: editSchedule?.profile_name } : null,
        name: editSchedule?.schedule_name || '',
        start: editSchedule?.start_date ? dayjs(editSchedule?.start_date).utc().local().format('YYYY-MM-DD HH:mm') : '',
    };


    const InviteUserSchema = Yup.object().shape({
        facility: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility').nonNullable("please select a facility"),
        area: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility area').nonNullable("please select a facility"),
        device: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a device').nonNullable("please select a facility"),
        profile: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a profile').nonNullable("please select a facility"),
        name: Yup.string().required('This name field is required.'),
        start: Yup.date("add valid date").required('This start field is required.').test('is-future', 'Past time is not valid', function (value) {
            const currentDateTime = dayjs(); // get the current date and time
            return dayjs(value).isAfter(currentDateTime); // validate that the selected time is in the future
        })
    });

    const handleAddSchedule = async (value) => {

        const data = {
            "facility_id": value?.facility?.value,
            "cultivation_area_id": value?.area?.value,
            "user_device_id": value?.device?.value,
            "profile_id": value?.profile?.value,
            "schedule_name": value?.name,
            "start_date": dayjs(value?.start).utc().format("DD/MM/YYYY HH:mm:ss"),
        }
        if (!isEdit) {
            dispatch(addScheduleData(data));
        } else {
            dispatch(editScheduleData({ data: data, id: editSchedule?.id }))
        }
    }

    const closeBtn = (
        <button className="close text-white" onClick={toggle} type="button">
            &times;
        </button>
    );

    return (
        <Modal returnFocusAfterClose={true} isOpen={open} backdrop={true} className="facility-modal position-relative">
            <Formik
                initialValues={initialFormValues}
                validationSchema={InviteUserSchema}
                onSubmit={(values) => {
                    handleAddSchedule(values);
                }}
            >
                {({
                    errors, touched, handleBlur, handleSubmit, getFieldProps, setFieldValue, values, setFieldTouched
                }) => (
                    <form onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(e);
                    }}>
                        <ModalHeader className="facility-modal-header text-white w-100" close={closeBtn}>
                            <p className="pr-2 mb-3 h4 text-white text-capitalize">
                                {isEdit ? 'Edit Schedule' : 'Add New Schedule'}
                            </p>
                        </ModalHeader>
                        <ModalBody className="facility-modal-body position-relative animation-transition-general">
                            <Row>
                                <Col sm={12}>
                                    <FormGroup className={errors.name && touched.name ? 'has-error' : ''}>
                                        <Label for="facility" className="mb-0">Facility</Label>
                                        <Select
                                            id="facility"
                                            name="facility"
                                            type='select'
                                            className="bg-dark basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            isDisabled={isEdit}
                                            onBlur={e => setFieldTouched("facility", true)}
                                            value={values.facility}
                                            options={[...facilityList.List?.map(facility => ({ value: facility?.id, label: facility?.facility_name }))]}
                                            onChange={(e) => {
                                                setFieldValue("facility", e);
                                                setFieldValue("area", null);
                                                setFieldValue("device", null);
                                                getAreaList(e.value);
                                                getDeviceList(e.value);
                                            }} />
                                        {errors.facility && touched.facility ? (
                                            <div className="invalid-feedback d-block">{errors.facility}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup className={errors.area && touched.area ? 'has-error' : ''}>
                                        <Label for="area" className="mb-0">Area</Label>
                                        <Select
                                            id="area"
                                            name="area"
                                            type='select'
                                            className="bg-dark basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            isDisabled={isEdit}
                                            onBlur={e => setFieldTouched("area", true)}
                                            value={values.area}
                                            invalid={errors.area && touched.area}
                                            options={[...areaList.List?.map(area => ({ value: area?.id, label: area?.cultivation_name }))]}
                                            onChange={(e) => {
                                                setFieldValue("area", e);
                                            }}
                                        />
                                        {errors.area && touched.area ? (
                                            <div className="invalid-feedback d-block">{errors.area}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup className={errors.device && touched.device ? 'has-error' : ''}>
                                        <Label for="device" className="mb-0">Device</Label>
                                        <Select
                                            id="device"
                                            name="device"
                                            type="select"
                                            className="bg-dark basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            isDisabled={isEdit}
                                            onBlur={e => setFieldTouched("device", true)}
                                            invalid={errors.device && touched.device}
                                            value={values.device}
                                            options={[...deviceList.List?.map((item, index) => ({ value: item?.id, label: item?.device_name }))]}
                                            onChange={(value) => {
                                                setFieldValue('device', value);
                                                setFieldValue("profile", null);
                                                getProfileList(value.value);
                                            }}
                                        // {...getFieldProps('device')}
                                        />
                                        {errors.device && touched.device ? (
                                            <div className="invalid-feedback d-block">{errors.device}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup className={errors.profile && touched.profile ? 'has-error' : ''}>
                                        <Label for="profile" className="mb-0">Profile</Label>
                                        <Select
                                            id="profile"
                                            name="profile"
                                            type="select"
                                            className="bg-dark basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            isDisabled={isEdit}
                                            onBlur={e => setFieldTouched("profile", true)}
                                            invalid={errors.profile && touched.profile}
                                            value={values.profile}
                                            options={[...profileList.List?.map((item, index) => ({ value: item?.id, label: item?.profile_name }))]}
                                            onChange={(value) => {
                                                setFieldValue('profile', value);
                                            }}
                                        // {...getFieldProps('profile')}
                                        />
                                        {errors.profile && touched.profile ? (
                                            <div className="invalid-feedback d-block">{errors.profile}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup className={errors.name && touched.name ? 'has-error' : ''}>
                                        <Label for="name" className="mb-0">Schedule Name</Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            type="text"
                                            className="bg-dark text-white"
                                            invalid={errors.name && touched.name}
                                            value={values.name}
                                            {...getFieldProps('name')}
                                        />
                                        <ErrorMessage name="name" component={FormFeedback} />
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup className={errors.start && touched.start ? 'has-error' : ''}>
                                        <Label for="start" className="mb-0">Start</Label>
                                        <ReactDatetime
                                            inputProps={{
                                                className: "form-control bg-dark text-white",
                                                placeholder: "Datetime Picker Here",
                                                id: "start",
                                                name: "start",
                                                invalid: errors.start && touched.start,
                                            }}
                                            value={values.start}
                                            onChange={date => setFieldValue('start', date)} // Handle Formik's value update
                                            closeOnSelect={true}
                                            timeFormat={true}
                                            minDate={new Date()}
                                            isValidDate={currentDate => dayjs(currentDate).isSameOrAfter(dayjs(), 'day')}  // Disable past dates
                                        />
                                        {/* <Input
                                            id="start"
                                            style={{ colorScheme: "dark" }}
                                            name="start"
                                            type="datetime-local"
                                            min={new Date().toISOString().slice(0, 16)}
                                            className="bg-dark text-white"
                                            invalid={errors.start && touched.start}
                                            value={values.start}
                                            {...getFieldProps('start')}
                                        /> */}
                                        <ErrorMessage name="start" component={FormFeedback} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter className="facility-modal-footer position-sticky bottom-0 ">
                            <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button" onClick={toggle}>
                                Close
                            </Button>
                            <Button className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="submit"
                                disabled={addSchedule.loading === "loading" || EditSchedule.loading === "loading"}
                            >{
                                    (addSchedule.loading === "loading" || EditSchedule.loading === "loading")
                                        ? <Spinner size="sm">{isEdit ? "Save" : "Create"}</Spinner> :
                                        <>{isEdit ? "Save" : "Create"}</>}
                            </Button>
                        </ModalFooter>
                    </form>)}
            </Formik>
        </Modal>

    )
}

export default ScheduleAddAndEdit