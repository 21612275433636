import { addDeviceRemoveStateData } from 'features/device/addDeviceSlice';
import { addDeviceData } from 'features/device/addDeviceSlice';
import { getDeviceTableData } from 'features/device/deviceTableSlice';
import { editDeviceData } from 'features/device/editDeviceSlice';
import { editDeviceRemoveStateData } from 'features/device/editDeviceSlice';
import { serialRemoveStateData } from 'features/device/serialSlice';
import { findSerial } from 'features/device/serialSlice';
import { ErrorMessage, Formik } from 'formik';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import * as Yup from 'yup';

const AddDeviceModel = ({ open, toggle, isEdit, editDevice, notify }) => {

  const serial = useSelector((state) => state.serial);
  const addDevice = useSelector((state) => state.addDevice);
  const EditDevice = useSelector((state) => state.editDevice);
  const facilityList = useSelector((state) => state.facilityList);
  const dispatch = useDispatch();
  const [isSerial, setIsSerial] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    isEdit ? setIsSerial(true) : setIsSerial(false);
  }, [isEdit])


  const findIfSerial = (value) => {
    // serial_number
    dispatch(findSerial({ serial_number: value }));
  }

  React.useEffect(() => {
    if (!isEdit && !isSerial && serial.loading === 'succeeded') {
      setIsSerial(true);
    }
    if (!isEdit && !isSerial && serial.loading === 'failed') {
      notify(serial.error?.data?.message,true);
      dispatch(serialRemoveStateData());
    }
  }, [serial.loading]);

  // const facilityList = [
  //   { value: "Facility1", label: "Facility1" },
  //   { value: "Facility2", label: "Facility2" },
  //   { value: "Facility3", label: "Facility3" },
  //   { value: "Facility4", label: "Facility4" },
  //   { value: "Facility5", label: "Facility5" },
  // ]
  const serialList = [
    { value: "serial1", label: "serial1" },
    { value: "serial2", label: "serial2" },
    { value: "serial3", label: "serial3" },
    { value: "serial4", label: "serial4" },
    { value: "serial5", label: "serial5" },
  ]

  const initialFormValues = {
    name: editDevice?.device_name || '',
    serial: editDevice?.device?.serial_number || '',
    facility: editDevice?.facility ? { value: editDevice?.facility?.id, label: editDevice?.facility?.facility_name } : { value: '', label: '' },
  }
  const InviteUserSchema = Yup.object().shape({
    name: Yup.string().min(5, "Minimum 5 characters").max(25, "Maximum 25 characters").required('This name field is required.'),
    facility: Yup.object().shape({ value: Yup.string(), label: Yup.string() }).required('please select a facility').nonNullable("please select a facility"),
    serial: Yup.string().required('This field is required to find the device'),
  });

  React.useEffect(() => {
    (async () => {
      if (isEdit) {
        if (EditDevice.loading === 'succeeded') {
          toggle();
          await dispatch(editDeviceRemoveStateData());
          await dispatch(getDeviceTableData());
        } else if(EditDevice.loading === 'failed') {
          notify(EditDevice?.error?.data?.message,true);}
          dispatch(editDeviceRemoveStateData());
      } else {
        if (addDevice.loading === 'succeeded') {
          toggle();
          await dispatch(addDeviceRemoveStateData());
          await dispatch(getDeviceTableData());
        } else if (addDevice.loading === 'failed') {
          notify(addDevice?.error?.data?.message,true);
          await dispatch(addDeviceRemoveStateData());
        }
      }
    })();
  }, [addDevice.loading,EditDevice.loading]);



  const handleDeviceSubmit = async (value) => {
    if (isEdit) {
      await dispatch(editDeviceData({ data:{"device_name": value?.name, "facility_id": value?.facility?.value}, id: editDevice?.id }));
    } else {
      await dispatch(addDeviceData({
        "device_id": serial.serialData?.data?.id,
        "device_name": value?.name,
        "facility_id": value?.facility?.value
      }));
    }
  }

  const closeBtn = (
    <button className="close text-white" onClick={toggle} type="button">
      &times;
    </button>
  );

  return (
    <Modal returnFocusAfterClose={true} isOpen={open} backdrop={true} className="facility-modal">
      <Formik
        initialValues={initialFormValues}
        validationSchema={InviteUserSchema}
        onSubmit={(values) => {
          handleDeviceSubmit(values);
        }}
      >
        {({
          errors, touched, handleBlur, handleSubmit, getFieldProps, setFieldValue, values,
        }) => (
          <form onSubmit={e => {
            handleSubmit(e);
          }}>
            <ModalHeader className="facility-modal-header text-white w-100" close={closeBtn}>
              <p className="pr-2 mb-3 h4 text-white text-capitalize">
                {isEdit ? 'Edit Device' : 'Add New Device'}
              </p>
            </ModalHeader>
            <ModalBody className="facility-modal-body position-relative">
              <Row>
                <Col sm={12}>
                  <FormGroup className={errors.serial && touched.serial ? 'has-error' : ''}>
                    <Label for="serial" className="mb-0">Device Serial</Label>
                    <Input
                      id="serial"
                      name="serial"
                      type="text"
                      className="bg-dark text-white-50"
                      disabled={isSerial}
                      invalid={errors.serial && touched.serial}
                      {...getFieldProps('serial')}
                    />
                    <ErrorMessage name="serial" component={FormFeedback} />
                  </FormGroup>
                </Col>
                <Col sm={12} className={isSerial ? 'd-none' : ""}>
                  <Button className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button"
                    disabled={values.serial?.trim().length === 0}
                    onClick={(e) => findIfSerial(values.serial?.trim())}
                  >
                    Search
                  </Button>
                </Col>
                <Col sm={12} className={!isSerial ? 'd-none' : ""}>
                  <FormGroup>
                    <Label for="name-model" className="mb-0">Device Model</Label>
                    <Input
                      id="name-model"
                      name="name-model"
                      type="text"
                      className="bg-dark text-white-50"
                      value={isEdit? editDevice?.device?.device_model : (serial.serialData?.data?.device_model||"")}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} className={!isSerial ? 'd-none' : ""}>
                  <FormGroup className={errors.name && touched.name ? 'has-error' : ''}>
                    <Label for="name" className="mb-0">Device Name</Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="bg-dark"
                      disabled={isEdit? EditDevice.loading==="loading":addDevice.loading==="loading"}
                      invalid={errors.name && touched.name}
                      {...getFieldProps('name')}
                    />
                    <ErrorMessage name="name" component={FormFeedback} />
                  </FormGroup>
                </Col>
                <Col sm={12} className={!isSerial ? 'd-none' : ""}>
                  <FormGroup className={errors.name && touched.name ? 'has-error' : ''}>
                    <Label for="facility" className="mb-0">facility Name</Label>
                    <Select
                      id="facility"
                      name="facility"
                      type="select"
                      className="bg-dark basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      invalid={errors.facility && touched.facility}
                      value={values.facility}
                      options={[...facilityList.List?.map(facility => ({ value: facility?.id, label: facility?.facility_name }))]}
                      onChange={(value) => {
                        setFieldValue('facility',value);
                      }}
                    // {...getFieldProps('facility')}
                    />
                    <ErrorMessage name="facility" component={FormFeedback} />
                  </FormGroup>
                </Col>
              </Row>

            </ModalBody>
            <ModalFooter className="facility-modal-footer">
              <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button" onClick={toggle}>
                Close
              </Button>
              <Button className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="submit"
                disabled={!isSerial||addDevice.loading==="loading"||EditDevice.loading==="loading"}
              >
                {(addDevice.loading==="loading"||EditDevice.loading==="loading")
                  ? <Spinner size="sm">Save</Spinner>:
                  <>Save</>}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddDeviceModel