import React from 'react';
import {
  Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, TabContent, TabPane
} from 'reactstrap';
import * as Yup from 'yup';
import { ErrorMessage, Formik, FieldArray } from 'formik';
import { Country, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import { addFacilityData } from 'features/facility/addFacilitySlice';
import { getFacilityTableData } from 'features/facility/facilityTableSlice';
import { editFacilityData } from 'features/facility/editFacilitySlice';
import Select from 'react-select';
import { addFacilityRemoveStateData } from 'features/facility/addFacilitySlice';
import { editFacilityRemoveStateData } from 'features/facility/editFacilitySlice';
const FacilityAddAndEdit = ({ open, toggle, isEdit, editFacility, notify }) => {
  const [horizontalTabs, setHorizontalTabs] = React.useState('Facility');
  const [countryList, setCountryList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);

  React.useEffect(() => {
    const list = Country.getAllCountries().map((item) => ({ name: item?.name, code: item?.isoCode }));
    setCountryList(list);
  }, []);

  React.useEffect(() => {
    isEdit && getCityList(editFacility?.country);
  }, [editFacility]);

  const changeActiveTab = (e, tabName) => {
    e.preventDefault();
    setHorizontalTabs(tabName);
  };

  const closeBtn = (
    <button className="close text-white" onClick={toggle} type="button">
      &times;
    </button>
  );

  const getCityList = (name) => {
    const Code = countryList.filter(country => country?.name === name)[0]?.code;
    const List = City.getCitiesOfCountry(Code)?.map((city) => ({ value: city?.name, label: city?.name })) || [];
    setCityList(List);
  };

  React.useEffect(() => {
    setHorizontalTabs('Facility');
  }, [open]);

  const initialFormValues = {
    name: editFacility?.facility_name || '',
    country: editFacility?.country || '',
    city: editFacility?.city || '',
    totalAreas: editFacility?.total_area || 1,
    areas: isEdit ? editFacility?.cultivation_areas.map((unit) => ({ area: unit?.cultivation_name || "" })) : [{ area: '' }]
  };

  const addFacility = useSelector((state) => state.addFacility);
  const EditFacility = useSelector((state) => state.editFacility);
  const dispatch = useDispatch();

  const InviteUserSchema = Yup.object().shape({
    name: Yup.string().required('This name field is required.'),
    country: Yup.string().required('This country field is required.'),
    city: Yup.string().required('This city field is required.'),
    totalAreas: Yup.number().min(1, 'You cannot add a negative number').max(30, 'You can add a maximum of 30 areas').required('Total areas is required.'),
    areas: Yup.array().of(
      Yup.object().shape({
        area: Yup.string().required('area name is required'),
      })
    ).test('match-length', 'Number of areas must match totalAreas', function (value) {
      const { totalAreas } = this.parent;
      return value.length === totalAreas;
    }).min(1, "At least one area is required"),
  });

  const handleAddFacility = async (values) => {

    try {
      const areas = values.areas.filter((areas, i) => (areas?.area.trim() !== ""));

      const data = {
        facility_name: values.name,
        country: values.country,
        city: values.city,
        total_area: values.totalAreas,
        areas: areas.map((areas) => (areas?.area)),
      }
      if (isEdit) {
        await dispatch(editFacilityData({ data, id: editFacility?.id }));
      } else {
        await dispatch(addFacilityData(data));
      }
      toggle();
      await dispatch(getFacilityTableData({ page: 1 }));
    } catch (error) {
      console.log("error==>",error);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (isEdit) {
        if (editFacility.loading === 'succeeded') {
          toggle();
          dispatch(editFacilityRemoveStateData());
        } else if (EditFacility.loading === 'failed') {
          notify(EditFacility?.error?.data?.message, true);
        }
        dispatch(editFacilityRemoveStateData());
      } else {
        if (addFacility.loading === 'succeeded') {
          toggle();
          dispatch(addFacilityRemoveStateData());
        } else if (addFacility.loading === 'failed') {
          notify(addFacility?.error?.data?.message, true);
          dispatch(addFacilityRemoveStateData());
        }
      }
    })();
  }, [addFacility.loading, EditFacility.loading]);


  return (
    <Modal returnFocusAfterClose={true} isOpen={open} backdrop={true} className="facility-modal">
      <Formik
        initialValues={initialFormValues}
        validationSchema={InviteUserSchema}
        onSubmit={(values) => {
          handleAddFacility(values);
        }}
      >
        {({
          errors, touched, handleBlur, handleSubmit, setFieldValue, values, setFieldError, setFieldTouched, setTouched
        }) => {
          
          const handleChange = (e,tab,isSubmit) => {
            e.preventDefault();
            if (!errors.totalAreas) {
              
              const currentAreas = values.areas;
              const newTotalAreas = values.totalAreas;

              if (newTotalAreas > currentAreas.length) {
                const updatedAreas = [...currentAreas, ...Array(newTotalAreas - currentAreas.length).fill({ area: '' })];
                
                setFieldValue('areas', updatedAreas);
                const updatedTouched=[];
                updatedAreas.map((item, index) => (
                  updatedTouched.push({ 'area': isSubmit||false })
                ))
                setTimeout(() => {
                  setTouched({...touched, areas: updatedTouched});
                }, 0);
              } else if (newTotalAreas < currentAreas.length) {
                const updatedAreas = currentAreas.slice(0, newTotalAreas);
                
                setFieldValue('areas', updatedAreas);
                const updatedTouched=[];
                updatedAreas.map((item, index) => (
                  updatedTouched.push({ 'area': isSubmit||false })
                ))
                setTimeout(() => {
                  setTouched({...touched, areas: updatedTouched});
                }, 0);
                
              } else if (newTotalAreas == currentAreas?.length) {
                const updatedTouched=[];
                const newTouched={};
                currentAreas.map((item, index) => (
                  updatedTouched.push({ 'area': isSubmit||false })
                ));

                Object.keys(errors).map((key, index) =>{
                  if(key==="areas"){
                    newTouched[key]={ key: updatedTouched};
                  }else{
                    newTouched[key]={ key: true};
                  }
                });
                if(horizontalTabs === 'Areas'){
                  setTimeout(() => {
                    setTouched({...touched, areas: updatedTouched});
                  }, 0);
                }else{
                  setTouched({...newTouched});
                }
              }
              !isSubmit ? changeActiveTab(e, tab) :  handleSubmit(e);
            }
          }
          return (
          <form onSubmit={e => {
            handleChange(e, 'Areas',true);
            
          }}>
            <ModalHeader className="facility-modal-header text-white w-100" close={closeBtn}>
              <p className="pr-2 mb-3 h4 text-white text-capitalize">
                {isEdit ? 'Edit Facility' : 'Add New Facility'}
              </p>
            </ModalHeader>
            <ModalBody className="facility-modal-body position-relative">
              <Row className="d-flex justify-content-between align-items-center border-bottom border-dark position-sticky facility-modal-tab-row">
                <Col
                  className={horizontalTabs === 'Facility' ? 'active-facility-modal-tab facility-modal-tab' : 'inactive-facility-modal-tab facility-modal-tab'}
                  onClick={(e) => changeActiveTab(e, 'Facility')}
                >
                  Facility
                </Col>
                <Col
                  className={horizontalTabs === 'Areas' ? 'active-facility-modal-tab facility-modal-tab' : 'inactive-facility-modal-tab facility-modal-tab'}
                  onClick={(e) => { handleChange(e, 'Areas',false)}}
                >
                  Areas
                </Col>
              </Row>
              <TabContent className="tab-space" activeTab={horizontalTabs}>
                <TabPane tabId="Facility">
                  <Row>
                    <Col sm={12}>
                      <FormGroup className={errors.name && touched.name ? 'has-error' : ''}>
                        <Label for="name" className="mb-0">Facility Name</Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="bg-dark"
                          value={values.name}
                          invalid={errors.name && touched.name}
                          onBlur={handleBlur}
                          onChange={(e) => setFieldValue('name', e.target.value)}
                        />
                        <ErrorMessage name="name" component={FormFeedback} />
                      </FormGroup>
                    </Col>
                    <Col sm={12}>
                      <FormGroup className={errors.country && touched.country ? 'has-error' : ''}>
                        <Label for="country" className="mb-0">Country</Label>
                        <Select
                          id="country"
                          name="country"
                          className="bg-dark basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          onBlur={() => setFieldTouched('country', true)}
                          options={[...countryList.map(country => ({ value: country.name, label: country.name }))]}
                          value={{ value: values.country, label: values.country }}
                          onChange={(e) => {
                            setFieldValue('country', e.value);
                            getCityList(e.value);
                            setFieldValue('city', null);
                          }}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: errors.country && touched.country ? '#dc3545' : base.borderColor,
                            })
                          }}
                        />
                        {errors.country && touched.country ? (
                          <div className="invalid-feedback d-block">{errors.country}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col sm={12}>
                      <FormGroup className={errors.city && touched.city ? 'has-error' : ''}>
                        <Label for="city" className="mb-0">City</Label>
                        <Select
                          id="city"
                          name="city"
                          className="bg-dark basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          options={cityList}
                          value={{ value: values.city, label: values.city }}
                          onBlur={() => setFieldTouched('city', true)}
                          onChange={(value) => {
                            setFieldValue('city', value?.value);
                          }}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: errors.city && touched.city ? '#dc3545' : base.borderColor,
                            })
                          }}
                        />
                        {errors.city && touched.city ? (
                          <div className="invalid-feedback d-block">{errors.city}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col sm={12}>
                      <FormGroup className={errors.totalAreas && touched.totalAreas ? 'has-error' : ''}>
                        <Label for="totalAreas" className="mb-0">Total Areas</Label>
                        <Input
                          id="totalAreas"
                          name="totalAreas"
                          type="number"
                          onBlur={handleBlur}
                          className="bg-dark"
                          value={values.totalAreas}
                          invalid={errors.totalAreas && touched.totalAreas}
                          onChange={(e) => setFieldValue('totalAreas', e.target.value)}
                        />
                        <ErrorMessage name="totalAreas" component={FormFeedback} />
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="Areas">
                  <Row>
                    <FieldArray name="areas">
                      {() => values.areas.map((area, index) => (
                        <Col sm={12} key={index}>
                          <FormGroup className={errors.areas?.[index]?.area && touched.areas?.[index]?.area ? 'has-error' : ''}>
                            <Label for={`areas.${index}.area`} className="mb-0">Area Name {index + 1}</Label>
                            <Input
                              id={`areas.${index}.area`}
                              name={`areas.${index}.area`}
                              type="text"
                              onBlur={e=>
                                setFieldTouched(`areas.${index}.area`,true)
                              }
                              className="bg-dark"
                              value={values.areas[index]?.area}
                              invalid={errors.areas?.[index]?.area && touched.areas?.[index]?.area}
                              onChange={(e) => setFieldValue(`areas.${index}.area`, e.target.value)}
                            />
                            {errors.areas?.[index]?.area && touched.areas?.[index]?.area ? <FormFeedback invalid className='d-flex mt-0'> {errors.areas?.[index]?.area}</FormFeedback> : <></>}
                            {/* <ErrorMessage name={`areas.${index}.area`} component={FormFeedback} /> */}
                          </FormGroup>
                        </Col>
                      ))}
                    </FieldArray>
                  </Row>
                </TabPane>
              </TabContent>
            </ModalBody>
            <ModalFooter className="facility-modal-footer">
              <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button" onClick={toggle}>
                Close
              </Button>
              <Button className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="submit"
                disabled={addFacility.loading === "loading" || EditFacility.loading === "loading"}>
                {addFacility.loading === "loading" || EditFacility.loading === "loading" ? <Spinner size="sm">Save</Spinner> : <>Save</>}
              </Button>
            </ModalFooter>
          </form>
        )}}
      </Formik>
    </Modal>
  );
};

export default FacilityAddAndEdit;
