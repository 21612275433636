import React from "react";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const CheckAuth = ({ children }) => {
  const token = secureLocalStorage.getItem("chart-token");
  const isAuthenticated = !!token;
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default CheckAuth;
