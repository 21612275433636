import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { DELETE_FACILITY_DATA } from 'utils/utils';

export const deleteFacilityData = createAsyncThunk(
  'deleteFacility/deleteFacilityData',
  async (data, { rejectWithValue }) => {

    const token = secureLocalStorage.getItem("chart-token");
    const config = {
      method: "delete",
      url: DELETE_FACILITY_DATA + data.id,
      headers: {
        Authorization: token,
      },
    };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

const initialState = {
  data: null,
  loading: 'idle',
  error: null,
};

export const deleteFacilitySlice = createSlice({
  name: 'deleteFacility',
  initialState,
  reducers: {
    deleteFacilityRemoveStateData: (state, action) => {
      state.loading = 'idle'
      state.data = null
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteFacilityData.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(deleteFacilityData.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.data = action.payload?.data
        state.error = null
      })
      .addCase(deleteFacilityData.rejected, (state, action) => {
        state.loading = 'failed'
        state.data = null
        state.error = action.payload
      })
  },
})


export const { deleteFacilityRemoveStateData } = deleteFacilitySlice.actions
export default deleteFacilitySlice.reducer;