// import { addProfileRemoveStateData } from 'features/device/addDevicePairingSlice';
// import { addProfileData } from 'features/device/addDevicePairingSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, CustomInput, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { useFormik } from 'formik';
import { inputsData, validationSchema, initialValues } from 'utils/AddProfileModelData';
import { addProfileData, addProfileRemoveStateData } from 'features/profile/addProfileSlice';
import { getProfileTableData } from 'features/profile/profileTableSlice';
import { editProfileRemoveStateData } from 'features/profile/editProfileSlice';
import { editProfileData } from 'features/profile/editProfileSlice';
import ReactDatetime from "react-datetime";
import dayjs from 'dayjs';

const AddProfileModel = ({ open, toggle, isEdit, editProfile, notify }) => {

    // const [moduleList, setModuleList] = React.useState([]);
    // const [selectedModuleList, setSelectedModuleList] = React.useState([]);
    const [pairingList, setPairingList] = React.useState([]);
    const [module, setModule] = React.useState(null);
    const deviceList = useSelector((state) => state.deviceList);
    // const devicePairings = useSelector((state) => state.devicePairings);
    const addProfile = useSelector((state) => state.addProfile);
    const EditProfile = useSelector((state) => state.editProfile);
    const profileTable = useSelector((state) => state.profileTable);
    const dispatch = useDispatch();



    // React.useEffect(() => {
    //     if (devicePairings.loading === "succeeded") {
    //         const list = devicePairings.List?.map((item) => {
    //             const cultivation_area = { value: item?.cultivation_area?.id, label: item?.cultivation_area?.cultivation_name };
    //             const device_module = item?.device_module?.id ? { value: item?.device_module?.id, label: item?.device_module?.module_name } : null;
    //             return {
    //                 cultivation_area,
    //                 device_module
    //             }
    //         });
    //         setPairingList(list);

    //         const ModuleList = [];
    //         list.map((item) => {
    //             return item?.device_module?.value ? ModuleList.push(item.device_module.value) : null;
    //         });
    //         setSelectedModuleList(ModuleList);
    //     }
    // }, [devicePairings.loading]);


    // React.useEffect(() => {
    //     if (devicePairings.loading === "succeeded") {

    //         const list = editProfile?.device?.device_modules?.map((item) => {
    //             return { value: item?.id, label: item?.module_name, isDisabled: selectedModuleList.includes(item?.id) };
    //         });
    //         setModuleList(list);
    //     }
    // }, [selectedModuleList]);

    React.useEffect(() => {
        if (addProfile.loading === "succeeded") {
            notify(addProfile.data?.message, false);
            toggle();
            dispatch(addProfileRemoveStateData());
            dispatch(getProfileTableData({ page: profileTable.page_information.current_page }))
        } else if (addProfile.loading === "failed") {
            notify(addProfile?.error?.data?.message, true);
            dispatch(addProfileRemoveStateData());
        }
    }, [addProfile.loading]);

    React.useEffect(() => {
        if (EditProfile.loading === "succeeded") {
            toggle();
        }
    }, [EditProfile.loading]);


    const handleSubmit = async (values) => {
        if (!isEdit) {
            dispatch(addProfileData(values));
        } else {
            dispatch(editProfileData({ data: values, id: editProfile?.id }))
        }
    }



    const closeBtn = (
        <button className="close text-white" onClick={toggle} type="button">
            &times;
        </button>
    );


    const formik = useFormik({
        initialValues: initialValues[module?.device_identifier || ""],
        validationSchema: validationSchema[module?.device_identifier || ""],
        onSubmit: async (values) => {
            handleSubmit(values);
        },
    });


    useEffect(() => {
        if (isEdit) {
            setModule({ value: editProfile?.user_device_id, label: editProfile?.device_name, device_identifier: editProfile?.device_identifier });
            formik.setFieldValue("profile_name", editProfile?.profile_name);
            formik.setFieldValue("user_device_id", editProfile?.user_device_id);
            editProfile?.cards?.map((item, index) => {
                if (item.title === "Temperature") {
                    const key = "temp_";
                    Object.keys(item?.data)?.map((data) => {
                        formik.setFieldValue(key + data, item?.data?.[data]);
                    });
                }
                if (item.title === "Humidity") {
                    const key = "humi_";
                    Object.keys(item?.data)?.map((data) => {
                        formik.setFieldValue(key + data, item?.data?.[data]);
                    });
                }
                if (item.title === "CO2") {
                    const key = "co2_";
                    Object.keys(item?.data)?.map((data) => {
                        formik.setFieldValue(key + data, item?.data?.[data]);
                    });
                }
                if (item.title === "Light") {
                    const key = "light_";
                    Object.keys(item?.data)?.map((data) => {
                        formik.setFieldValue(key + data, item?.data?.[data]);
                    });
                }
                if (item.title === "Brightness") {
                    const key = "light_";
                    Object.keys(item?.data)?.map((data) => {
                        formik.setFieldValue(key + data, item?.data?.[data]);
                    });
                }
                if (item.title === "Configurations") {
                    Object.keys(item?.data)?.map((data) => {
                        formik.setFieldValue(data, item?.data?.[data]);
                    });
                }
                return;
            })
        }
    }, [editProfile]);

    const handleDefaultValue = async(device_identifier) => {
        if (!isEdit) {
            inputsData[device_identifier || ""].map((item, index) => {
                index > 0 && formik.setFieldValue(item?.name, initialValues[device_identifier || ""]?.[item?.name]);
            })
        }
    }

    return (
        <>
            <Modal returnFocusAfterClose={true} isOpen={open} backdrop={true} size='lg' className="facility-modal">
                <form onSubmit={async (e) => {
                    e.preventDefault();
                    const errors = await formik.validateForm();
                    if (Object.keys(errors).length) {
                        const touchedFields = {};
                        Object.keys(errors).forEach((key) => {
                            touchedFields[key] = true;
                        });
                        formik.setTouched(touchedFields);
                    } else {
                        formik.handleSubmit(e);
                    }
                }}>
                    <ModalHeader className="facility-modal-header text-white w-100" close={closeBtn}>
                        <p className="pr-2 mb-3 h4 text-white text-capitalize">
                            {isEdit ? 'Edit Profile' : 'Add New Profile'}
                        </p>
                    </ModalHeader>
                    <ModalBody className="facility-modal-body">
                        <Row>
                            {
                                inputsData[module?.device_identifier || ""].map((item, index) => {

                                    return (
                                        <>
                                            <Col sm={12} lg={!item.isInput ? 12 : index < 2 ? 12 : 4} key={index}>
                                                {!item.isInput
                                                    ? (<Label for={item.id} className="mb-1 mt-2">{item?.label}</Label>)
                                                    : item.type === "checkbox"
                                                        ? (<FormGroup >
                                                            <CustomInput
                                                                type="switch"
                                                                id={item?.name}
                                                                name={item?.name}
                                                                label={item?.label}
                                                                onBlur={formik?.handleBlur}
                                                                value={formik.values[item?.name]}
                                                                checked={formik.values[item?.name]}
                                                                onChange={e => formik.setFieldValue(item?.name, e.target.checked)}
                                                            />
                                                            {formik?.errors[item?.name] && formik?.touched[item?.name] ? <FormFeedback invalid className='d-flex mt-0'> {formik?.errors[item?.name]}</FormFeedback> : null}

                                                        </FormGroup>)
                                                        : (<FormGroup>
                                                            <Label for={item.id} className="mb-0">{item?.label}</Label>
                                                            {item?.InputGroupText ? <>
                                                                <InputGroup className='mb-0'>
                                                                    <InputGroupText className='bg-dark border border-bottom-0 border-left-0 border-top-0 border-darker border-default'>
                                                                        {item?.InputGroupText}
                                                                    </InputGroupText>
                                                                    <Input
                                                                        id={item?.id}
                                                                        name={item?.name}
                                                                        type={item?.type}
                                                                        className="bg-dark"
                                                                        invalid={formik?.errors[item?.name] && (formik?.touched[item?.name] || false)}
                                                                        onBlur={(e) => {
                                                                            formik?.handleBlur(e);
                                                                        }}
                                                                        value={formik?.values[item?.name]}
                                                                        onChange={(e) => {
                                                                            formik?.handleChange(e);
                                                                        }} />
                                                                </InputGroup>
                                                                {formik?.errors[item?.name] && (formik?.touched[item?.name] || false) ? <FormFeedback invalid className='d-flex mt-0'> {formik?.errors[item?.name]}</FormFeedback> : null}
                                                            </>
                                                                :
                                                                <>
                                                                    {
                                                                        item?.type === "time" ?
                                                                            <ReactDatetime
                                                                                dateFormat={false}
                                                                                timeFormat="HH:mm"
                                                                                timeConstraints={{
                                                                                    hours: { min: 0, max: 23 }, // Set time constraints if necessary
                                                                                    minutes: { min: 0, max: 59 }
                                                                                }}
                                                                                inputProps={{
                                                                                    className: "form-control bg-dark text-white",
                                                                                    id: item?.id,
                                                                                    name: item?.name,
                                                                                    invalid: formik?.errors[item?.name] && formik?.touched[item?.name],
                                                                                    onBlur: formik?.handleBlur
                                                                                }}
                                                                                value={formik?.values[item?.name]}
                                                                                onChange={(e) => {
                                                                                    formik?.setFieldValue(item?.name, dayjs(e).format("HH:mm") ); // e.target.value);
                                                                                }}
                                                                            />
                                                                            :
                                                                            <Input
                                                                                id={item?.id}
                                                                                name={item?.name}
                                                                                type={item?.type}
                                                                                className="bg-dark"
                                                                                invalid={formik?.errors[item?.name] && formik?.touched[item?.name]}
                                                                                onBlur={(e) => {
                                                                                    formik?.handleBlur(e);
                                                                                }}
                                                                                value={formik?.values[item?.name]}
                                                                                onChange={(e) => {
                                                                                    formik?.handleChange(e);
                                                                                }} />}
                                                                    {formik?.errors[item?.name] && formik?.touched[item?.name] ? <FormFeedback invalid className='d-flex mt-0'> {formik?.errors[item?.name]}</FormFeedback> : null}
                                                                </>
                                                            }

                                                        </FormGroup>)}
                                            </Col>
                                            {
                                                index === 0 && <Col sm={12}>
                                                    <FormGroup>
                                                        <Label for="device" className="mb-0">Device Model</Label>
                                                        <Select
                                                            id="device"
                                                            name="device"
                                                            type='select'
                                                            className="bg-dark basic-single"
                                                            classNamePrefix="select"
                                                            isDisabled={isEdit}
                                                            isSearchable={true}
                                                            value={module}
                                                            options={deviceList.List?.map((item, index) => ({ value: item?.id, label: item?.device_name, device_identifier: item?.device_identifier }))}
                                                            onChange={(e) => {
                                                                if(module === null){
                                                                    handleDefaultValue(e?.device_identifier);
                                                                }
                                                                setModule(e);
                                                                formik.setFieldValue('user_device_id', e.value);
                                                            }} />
                                                        {formik?.errors["user_device_id"] && formik?.touched[item?.name] ? <FormFeedback invalid className='d-flex mt-0'> {formik?.errors["user_device_id"]}</FormFeedback> : null}

                                                    </FormGroup>
                                                </Col>
                                            }
                                        </>
                                    );
                                })}
                        </Row>
                    </ModalBody>
                    <ModalFooter className="facility-modal-footer">
                        <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button" onClick={toggle}>
                            Close
                        </Button>
                        <Button className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="submit"
                            // onClick={handleSubmit}
                            disabled={addProfile.loading === "loading" || EditProfile.loading === "loading"}
                        >
                            {
                                (addProfile.loading === "loading" || EditProfile.loading === "loading")
                                    ? <Spinner size="sm">{isEdit ? "Saving" : "Creating"}</Spinner> :
                                    <>{isEdit ? "Save" : "Create "}</>
                            }
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddProfileModel