import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { ADD_FACILITY_DATA } from 'utils/utils';

export const addFacilityData = createAsyncThunk(
  'addFacility/addFacilityData',
  async (data,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "post",
        url: ADD_FACILITY_DATA,
        data:data,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  data: null,
  loading: 'idle',
  error: null,
};

export const addFacilitySlice = createSlice({
  name: 'addFacility',
  initialState,
  reducers: {
    addFacilityRemoveStateData :(state,action)=>{
          state.loading = 'idle'
          state.data = null
          state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFacilityData.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(addFacilityData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.data = action.payload?.data
          state.error = null
      })
      .addCase(addFacilityData.rejected, (state, action) => {
          state.loading = 'failed'
          state.data = null
          state.error = action.payload
      })
  },
})

export const{ addFacilityRemoveStateData } = addFacilitySlice.actions

export default addFacilitySlice.reducer;