import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_ALARM_DATA } from 'utils/utils';

export const getAlarmTableData = createAsyncThunk(
  'alarmTable/getAlarmTableData',
  async (params,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: GET_ALARM_DATA,
        params:params,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  page_Data: null,
  page_information: {
    "total_data": 0,
    "last_page": 0,
    "current_page": 0,
    "previous_page": 0,
    "next_page": 0
},
  loading: 'idle',
  error: null,
};

export const alarmTableSlice = createSlice({
  name: 'alarmTable',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAlarmTableData.pending, (state, action) => {
          state.loading = 'loading'
          state.error = null
      })
      .addCase(getAlarmTableData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.page_Data = action.payload?.data?.data?.page_data
          state.page_information = action.payload?.data?.data?.page_information
          state.error = null
      })
      .addCase(getAlarmTableData.rejected, (state, action) => {
          state.loading = 'failed'
          state.page_Data = null
          state.page_information = initialState.page_information
          state.error = action.payload
      })
  },
})


export default alarmTableSlice.reducer;