
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Spinner,
  Label,
  FormGroup,
  Row,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN } from "utils/utils";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUserData } from "features/user/userSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({});
  const [value, setValue] = React.useState({
    email: "",
    password: ""
  });
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const handleLogin = async () => {
    setLoading(true);
    const signInPayload = {
      email: value.email,
      password: value.password
    };

    const config = {
      method: "post",
      url: LOGIN,
      data: signInPayload,
    };

    axios(config)
      .then((response) => {
        if ((response.data.success = true)) {
          secureLocalStorage.setItem(
            "chart-token",
            response?.data?.data?.token
          );
          setMessage("");
          navigate("/admin/dashboard");
          dispatch(getUserData());
        }
      })
      .catch((error) => {
        console.log("error===>", error);
        setMessage(error?.response?.data?.message);
        setLoading(false);
      })
  }



  return (
    <>
      <div className="wrapper wrapper-full-page">
        <div className="full-page login-page">
          <div className="content h-100vh full-page d-flex justify-content-center align-items-center">
            <Container>
              <div className="row d-flex justify-content-center align-items-center ">
                <img alt="..." src={require("assets/img/logo__.png")} className="card-img-top p-3 register-logo-img mb-4" />
              </div>
              <Col className="ml-auto mr-auto mt-auto mb-auto" lg="4" md="6">
                <Form className="form">
                  <Card className="card-login card-white">
                    <CardHeader className="pb-3">
                      <div className="d-flex flex-column justify-content-around align-items-center h-25 ">
                        <img alt="..." src={require("assets/img/logo__black_.png")} className="login-card-img-top" />
                        <h3 className=" mb-0 login-card-title"> Sign in to your account </h3>
                      </div>
                      {/* <CardTitle tag="h1">Log in</CardTitle> */}
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": state.emailFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          disabled={loading}
                          onFocus={(e) => setState({ ...state, emailFocus: true })}
                          onBlur={(e) => setState({ ...state, emailFocus: false })}
                          onChange={(e) =>
                            setValue({ ...value, email: e.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": state.passFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          name="password"
                          type="password"
                          disabled={loading}
                          invalid={message?.length > 0 ? true : false}
                          onFocus={(e) => setState({ ...state, passFocus: true })}
                          onBlur={(e) => setState({ ...state, passFocus: false })}
                          onChange={(e) =>
                            setValue({ ...value, password: e.target.value })
                          }
                        />
                      </InputGroup>
                      <p className="text-danger">{message}</p>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="mb-3"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleLogin();
                        }}
                        size="lg"
                        disabled={
                          loading ||
                          value.email.trim() === "" ||
                          value.password === ""
                        }
                      >
                        {loading && <Spinner size="sm">Loading...</Spinner>}
                        {loading ? "Loading..." : "Sign In"}
                      </Button>
                      <div className="pull-left">
                        <h6>
                          <Link to="/register" className="link footer-link">
                            Sign Up
                          </Link>
                        </h6>
                      </div>
                      <div className="pull-right">
                        <h6>
                          <Link to="#" className="link footer-link">
                            Forgot password
                          </Link>
                        </h6>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
      </div>
    </>
  )


  // return (
  //   <>
  //     <div className="wrapper wrapper-full-page">
  //       <div className="full-page login-page">
  //         <div className="content">
  //           <Container>
  //             <Col className="ml-auto mr-auto" lg="4" md="6">
  //               <Form className="form" >
  //                 <Card className="card-login card-white bg-gray-dark">
  //                   <CardHeader className="pb-2">
  //                     <img alt="..." src={require("assets/img/logo__.png")} className="card-img-top p-3" />
  //                     <h1 style={{ fontSize: "2.2em", marginTop: "120px" }} className="ml-3 font-weight-bold text-white">
  //                       {/* <CardTitle tag="h1" className=" mt-3 ml-3"> */}
  //                       Sign In
  //                     {/* </CardTitle> */}
  //                     </h1>

  //                   </CardHeader>
  //                   <CardBody>
  //                     <FormGroup >
  //                       <Label for='Email' className="mb-0 h3 text-white">Email</Label>
  //                       <Input
  //                         placeholder="Email"
  //                         type="email"
  //                         name="email"
  //                         className="form-control bg-white text-dark"
  //                         disabled={loading}
  //                         onFocus={(e) => setState({ ...state, emailFocus: true })}
  //                         onBlur={(e) => setState({ ...state, emailFocus: false })}
  //                         onChange={(e) =>
  //                           setValue({ ...value, email: e.target.value })
  //                         }
  //                       />
  //                     </FormGroup>
  //                     <FormGroup >
  //                       <Label for='password' className="mb-0 h3 text-white">Password</Label>
  //                       <Input
  //                         placeholder="Password"
  //                         name="password"
  //                         type="password"
  //                         className="form-control bg-white text-dark"
  //                         disabled={loading}
  //                         invalid={message?.length > 0 ? true : false}
  //                         onFocus={(e) => setState({ ...state, passFocus: true })}
  //                         onBlur={(e) => setState({ ...state, passFocus: false })}
  //                         onChange={(e) =>
  //                           setValue({ ...value, password: e.target.value })
  //                         }
  //                       />
  //                     </FormGroup>
  //                     <p className="text-danger">{message}</p>
  //                   </CardBody>
  //                   <CardFooter>
  //                     <Button
  //                       className="mb-1 w-100"
  //                       color="primary"
  //                       type="submit"
  //                       onClick={(e) => {
  //                         e.preventDefault();
  //                         handleLogin();
  //                       }}
  //                       size="lg"
  //                       disabled={
  //                         loading ||
  //                         value.email.trim() === "" ||
  //                         value.password === ""
  //                       }
  //                     >
  //                       {loading && <Spinner size="sm">Loading...</Spinner>}
  //                       {loading ? "Loading..." : "Sign In"}
  //                     </Button>
  //                     <div className="pull-left">
  //                       <h6>
  //                         <Link to="/register" className="link footer-link">
  //                           Create Account
  //                         </Link>
  //                       </h6>
  //                     </div>
  //                     <div className="pull-right">
  //                       <h6>
  //                         <Link to="#" className="link footer-link">
  //                         Forgot password
  //                         </Link>
  //                         {/* <a
  //                           className="link footer-link"
  //                           href="#pablo"
  //                           onClick={(e) => e.preventDefault()}
  //                         >
  //                           Need Help?
  //                         </a> */}
  //                       </h6>
  //                     </div>
  //                   </CardFooter>
  //                 </Card>
  //               </Form>
  //             </Col>
  //           </Container>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default Login;
