
import * as Yup from 'yup';

export const initialValues = {
    "": {
        facility: null,
        device: null,
        area: null,
        device_module: "",
    },
    "0": {
        facility: null,
        device: null,
        area: null,
        device_module: "",
    },
    "1": {
        facility: null,
        device: null,
        area: null,
        device_module: "",
        is_temp: true,
        is_ppm: true,
        is_humidity: true,
        temp_max: 0,
        temp_min: 0,
        humi_max: 0,
        humi_min: 0,
        co2_max: 0,
        co2_min: 0,
    },
}

export const validationSchema = {

    "": Yup.object().shape({
        facility: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility').nonNullable("please select a facility"),
        device: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a device').nonNullable("please select a facility"),
        area: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility area').nonNullable("please select a facility"),
        device_model: Yup.string().required("select area that has device model"),
    }),
    "0": Yup.object().shape({
        facility: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility').nonNullable("please select a facility"),
        device: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a device').nonNullable("please select a facility"),
        area: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility area').nonNullable("please select a facility"),
        device_model: Yup.string().required("select area that has device model"),
    }),
    "1": Yup.object().shape({
        facility: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility').nonNullable("please select a facility"),
        device: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a device').nonNullable("please select a facility"),
        area: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required('please select a facility area').nonNullable("please select a facility area"),
        device_module: Yup.object().shape({ id: Yup.string().required(), name: Yup.string().required() }).required("select area that has device module").nonNullable("select area that has device module"),
        is_temp: Yup.boolean().required("Temperature c is Required").default(true),
        is_ppm: Yup.boolean().required("co2 ppm is Required").default(true),
        is_humidity: Yup.boolean().required("Humidity rh is Required").default(true),
        temp_max: Yup.number().when('is_temp', {
            is: (value)=> value === true,
            then: (s)=> s.required("Temperature max is required").min(10, "minimum value is 10").max(40, "maximum value is 40"),
            otherwise: (s)=> s.notRequired()
        }),
        temp_min: Yup.number().when('is_temp', { 
            is: (value)=> value === true,
            then:(s)=> s.required("Temperature min is required").test('is-less-than-temp-max', 'Temperature min must be less than Temperature max', function (value) {
                return value < this.parent.temp_max ;
            }).min(10, "minimum value is 10").max(40, "maximum value is 40"),
            otherwise:(s)=> s.notRequired()
        }),
        humi_max: Yup.number().when('is_humidity', {
            is:(value)=> value === true,
            then: (s)=> s.required("Humidity max is required").min(20, "minimum value is 20").max(100, "maximum value is 100"),
            otherwise: (s)=> s.notRequired()
        }),
        humi_min: Yup.number().when('is_humidity', {
            is:(value)=> value === true,
            then:(s)=> s.required("Humidity min is required").test('is-less-than-humi-max', 'Humidity min must be less than Humidity max', function (value) {
                return value < this.parent.humi_max;
            }).min(20, "minimum value is 20").max(100, "maximum value is 100"),
            otherwise:(s)=> s.notRequired()
        }),
        co2_max: Yup.number().when('is_ppm', {
            is:(value)=> value === true,
            then:(s)=> s.required("co2 max is required").min(0, "minimum value is 0").max(3500, "maximum value is 3500"),
            otherwise:(s)=> s.notRequired()
        }),
        co2_min: Yup.number().when('is_ppm', {
            is:(value)=> value === true,
            then:(s)=> s.required("co2 min is required").test('is-less-than-co2-max', 'co2 min must be less than co2 max', function (value) {
                return value < this.parent.co2_max;
            }).min(0, "minimum value is 0").max(3500, "maximum value is 3500"),
            otherwise:(s)=> s.notRequired()
        }),
    }),
}

export const inputsData = {
    "": [
        // { name: "user_device_id", label: "Device Id", type: "text", key: "user_device_id", id: "user_device_id", InputGroupText:"", isInput:true },
    ],
    "0": [
        // { name: "user_device_id", label: "Device Id", type: "text", key: "user_device_id", id: "user_device_id", InputGroupText:"", isInput:true },
    ],
    "1": [
        // { name: "user_device_id", label: "Device Id", type: "text", key: "user_device_id", id: "user_device_id", InputGroupText:"", isInput:true },
        { label: " ", isInput: false },
        { name: "is_temp", label: <>Temreature C&deg;</>, type: "checkbox", key: "is_temp", id: "is_temp", InputGroupText: "", isInput: true },
        { name: "is_humidity", label: "Humidify RH%", type: "checkbox", key: "is_humidity", id: "is_humidity", InputGroupText: "", isInput: true },
        { name: "is_ppm", label: <> Co<sub>2</sub> PPM</>, type: "checkbox", key: "is_ppm", id: "is_ppm", InputGroupText: "", isInput: true },
        { label: " ", isInput: false },
        { name: "temp_max", label: "Temperature Max", type: "number", key: "temp_max", id: "temp_max", InputGroupText: <>&deg;C</>, isInput: true },
        { name: "temp_min", label: "Temperature Min", type: "number", key: "temp_min", id: "temp_min", InputGroupText: <>&deg;C</>, isInput: true },
        { label: " ", isInput: false },
        { name: "humi_max", label: "Humidity Max", type: "number", key: "humi_max", id: "humi_max", InputGroupText: <>%</>, isInput: true },
        { name: "humi_min", label: "Humidity Min", type: "number", key: "humi_min", id: "humi_min", InputGroupText: <>%</>, isInput: true },
        { label:" ", isInput: false },
        { name: "co2_max", label: <> Co<sub>2</sub> Max</>, type: "number", key: "co2_max", id: "co2_max", InputGroupText: <>PPM</>, isInput: true },
        { name: "co2_min", label: <> Co<sub>2</sub> Min</>, type: "number", key: "co2_min", id: "co2_min", InputGroupText: <>PPM</>, isInput: true },
    ],
}