import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';  
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { FINED_SERIAL } from 'utils/utils';

export const findSerial = createAsyncThunk(
  'serial/findSerial',
  async (params,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: FINED_SERIAL,
        params:params,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  serialData:null ,
  loading: 'idle',
  error: null,
};

export const serialSlice = createSlice({
  name: 'serial',
  initialState,
  reducers: {
    
    serialRemoveStateData :(state,action)=>{
      state.loading = 'idle'
      state.serialData = null
      state.error = null
}
  },
  extraReducers: (builder) => {
    builder
      .addCase(findSerial.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(findSerial.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.serialData = action.payload?.data
          state.error = null
      })
      .addCase(findSerial.rejected, (state, action) => {
          state.loading = 'failed'
          state.serialData = null
          state.error = action.payload
      })
  },
})

export const {serialRemoveStateData} = serialSlice.actions
export default serialSlice.reducer;