const bigScreenChartOptions ={
    maintainAspectRatio: true,
    height: 700,
    plugins:{
      legend:{
        display: true
      }
    },
    interaction: {
      intersect: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    aspectRatio: 2,
    scales: {
      yAxes: {
        barPercentage: 1.6,
        // display: false,
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
      y2: {
        type: "linear",
        display: true,
        // display: false,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y1: {
        type: "linear",
        display: true,
        // display: false,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        type: "linear",
        display: true,
        // display: false,
        position: "left",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },

      xAxes: {
        barPercentage: 1.6,
        minWidth: 200,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 10,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    },
  }
const smallScreenChartOptions ={
    maintainAspectRatio: true,
    plugins:{
      legend:{
        display: false
      }
    },
    interaction: {
      mode: 'x'
    },
    elements:{
      point:{
        radius: 0,
        hitRadius: 3
      },
      line:{
        borderWidth:2,
      }
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    aspectRatio: 2,
    scales: {
      yAxes: {
        barPercentage: 1.6,
        display: false,
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
      y2: {
        type: "linear",
        // display: true,
        display: false,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y1: {
        type: "linear",
        // display: true,
        display: false,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        type: "linear",
        // display: true,
        display: false,
        position: "left",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },

      xAxes: {
        barPercentage: 1.6,
        minWidth: 200,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 10,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    },
  }

  export {bigScreenChartOptions, smallScreenChartOptions}