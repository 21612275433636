
import Dashboard from "views/Dashboard.js";
import Facility from "views/Facility.js";
import Profiles from "views/Profiles.js";
import Schedule from "views/Schedule.js";
import Alarm from "views/Alarm.js";
import Logs from "views/Logs.js";
import Device from "views/Device";
import { faCalendarDays, faCircleExclamation, faGauge, faGear, faMagnifyingGlassChart, faPlug, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import TelegramId from "views/TelegramId";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    // icon: "tim-icons icon-chart-pie-36",
    icon: faGauge,
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/Facility",
    name: "Facility",
    rtlName: "التقويم",
    // icon: "tim-icons icon-chart-bar-32",
    icon: faSeedling,
    component: <Facility />,
    layout: "/admin",
  },
  {
    path: "/Device",
    name: "Device",
    rtlName: "التقويم",
    // icon: "tim-icons icon-settings-gear-63",
    icon: faPlug,
    component: <Device />,
    layout: "/admin",
  },
  {
    path: "/Profiles",
    name: "Profiles",
    rtlName: "التقويم",
    // icon: "tim-icons icon-single-02",
    icon: faGear,
    component: <Profiles />,
    layout: "/admin",
  },
  {
    path: "/Schedule",
    name: "Schedule",
    rtlName: "التقويم",
    // icon: "tim-icons icon-time-alarm",
    icon: faCalendarDays,
    component: <Schedule />,
    layout: "/admin",
  },
  {
    path: "/Alarm",
    name: "Alarm",
    rtlName: "التقويم",
    // icon: "tim-icons icon-bell-55",
    icon: faCircleExclamation,
    component: <Alarm />,
    layout: "/admin",
  },
  {
    path: "/Logs",
    name: "Logs",
    rtlName: "التقويم",
    // icon: "tim-icons icon-notes",
    icon: faMagnifyingGlassChart,
    component: <Logs />,
    layout: "/admin",
  },
  {
    path: "/Telegram",
    name: "Telegram",
    rtlName: "التقويم",
    icon: faTelegram,
    component: <TelegramId />,
    layout: "/admin",
  },
];

export default routes;
