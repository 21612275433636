import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_TELEGRAM_DATA } from 'utils/utils';

export const getTelegramTableData = createAsyncThunk(
  'telegramTable/getTelegramTableData',
  async (_,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: GET_TELEGRAM_DATA,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  page_Data: null,
  loading: 'idle',
  error: null,
};

export const telegramTableSlice = createSlice({
  name: 'telegramTable',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTelegramTableData.pending, (state, action) => {
          state.loading = 'loading'
          state.error = null
      })
      .addCase(getTelegramTableData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.page_Data = action.payload?.data?.data
          state.error = null
      })
      .addCase(getTelegramTableData.rejected, (state, action) => {
          state.loading = 'failed'
          state.page_Data = null
          state.error = action.payload
      })
  },
})


export default telegramTableSlice.reducer;