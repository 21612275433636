import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { ADD_PROFILE } from 'utils/utils';

export const addProfileData = createAsyncThunk(
  'addProfile/addProfileData',
  async (data,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "post",
        url: ADD_PROFILE,
        data:data,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  data: null,
  loading: 'idle',
  error: null,
};

export const addProfileSlice = createSlice({
  name: 'addProfile',
  initialState,
  reducers: {
    addProfileRemoveStateData :(state,action)=>{
          state.loading = 'idle'
          state.data = null
          state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProfileData.pending, (state, action) => {
          state.loading = 'loading'
          state.error = null
      })
      .addCase(addProfileData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.data = action.payload?.data
          state.error = null
      })
      .addCase(addProfileData.rejected, (state, action) => {
          state.loading = 'failed'
          state.data = null
          state.error = action.payload
      })
  },
})


export const { addProfileRemoveStateData } = addProfileSlice.actions

export default addProfileSlice.reducer;
