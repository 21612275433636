import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_DEVICE_PAIRINGS } from 'utils/utils';

export const getDevicePairingsData = createAsyncThunk(
  'devicePairings/getDevicePairingsData',
  async (payload,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: GET_DEVICE_PAIRINGS+payload?.id,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  List: [],
  loading: 'idle',
  error: null,
};

export const devicePairingsSlice = createSlice({
  name: 'devicePairings',
  initialState,
  reducers: {
    removeDevicePairingsStateData: (state, action) => {
      state.List = []
      state.loading = 'idle'
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDevicePairingsData.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(getDevicePairingsData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.List = action.payload?.data?.data
          state.error = null
      })
      .addCase(getDevicePairingsData.rejected, (state, action) => {
          state.loading = 'failed'
          state.List = []
          state.error = action.payload
      })
  },
})

export const { removeDevicePairingsStateData } = devicePairingsSlice.actions
export default devicePairingsSlice.reducer;