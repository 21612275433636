// import { addProfileRemoveStateData } from 'features/device/addDevicePairingSlice';
// import { addProfileData } from 'features/device/addDevicePairingSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, CustomInput, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { useFormik } from 'formik';
import { inputsData, validationSchema, initialValues } from 'utils/AddAlarmModelData';
import { getAreaListData } from 'features/facility/areaListSlice';
import { getDeviceListData } from 'features/device/deviceListSlice';
import { getDevicePairingsData } from 'features/device/devicePairingsSlice';
import { addAlarmData } from 'features/alarm/addAlarmSlice';
import { addAlarmRemoveStateData } from 'features/alarm/addAlarmSlice';
import { editAlarmRemoveStateData } from 'features/alarm/editAlarmSlice';
import { getAlarmTableData } from 'features/alarm/alarmTableSlice';
import { editAlarmData } from 'features/alarm/editAlarmSlice';

const AddAlarmModel = ({ open, toggle, isEdit, editAlarm, notify }) => {

    // const [moduleList, setModuleList] = React.useState([]);
    // const [selectedModule, setSelectedModule] = React.useState([]);

    const [area, setArea] = React.useState([]);
    const [module, setModule] = React.useState(null);
    const facilityList = useSelector((state) => state.facilityList);
    const areaList = useSelector((state) => state.areaList);
    const deviceList = useSelector((state) => state.deviceList);
    const devicePairings = useSelector((state) => state.devicePairings);
    const addAlarm = useSelector((state) => state.addAlarm);
    const EditAlarm = useSelector((state) => state.editAlarm);
    const alarmTable = useSelector((state) => state.alarmTable);
    const dispatch = useDispatch();



    React.useEffect(() => {
        if (devicePairings.loading === "succeeded") {
            const list = devicePairings.List?.map((item) => {
                const cultivation_area = { value: item?.cultivation_area?.id, label: item?.cultivation_area?.cultivation_name, isDisabled: item?.device_module?.id ? false : true };
                const device_module = item?.device_module?.id ? { id: item?.device_module?.id, name: item?.device_module?.module_name } : "";
                return {
                    cultivation_area,
                    device_module
                }
            });
            setArea(list);
        }
    }, [devicePairings.loading]);



    React.useEffect(() => {
        if (addAlarm.loading === "succeeded") {
            notify(addAlarm.data?.message, false);
            toggle();
            dispatch(addAlarmRemoveStateData());
            dispatch(getAlarmTableData({ page: alarmTable.page_information.current_page }))
        } else if (addAlarm.loading === "failed") {
            notify(addAlarm?.error?.data?.message, true);
            dispatch(addAlarmRemoveStateData());
        }
    }, [addAlarm.loading]);

    React.useEffect(() => {
        if (EditAlarm.loading === "succeeded") {
            toggle();
            notify(EditAlarm.data?.message,false);
            dispatch(editAlarmRemoveStateData());
            dispatch(getAlarmTableData({page:alarmTable.page_information.current_page}))
        } else if (EditAlarm.loading === "failed") {
            notify(EditAlarm?.error?.data?.message,true);
            dispatch(editAlarmRemoveStateData());
        }
    }, [EditAlarm.loading]);




    const handleSubmit = async (values) => {
        const payload = {
            "facility_id": values.facility?.value,
            "cultivation_area_id": values.area?.value,
            "user_device_id": values.device?.value,
            "device_module_id": values.device_module?.id,
        }

        if (values?.is_temp.toString()) {
            payload["is_temp"] = values?.is_temp.toString();
        }
        if (values?.is_ppm.toString()) {
            payload["is_ppm"] = values?.is_ppm.toString();
        }
        if (values?.is_humidity.toString()) {
            payload["is_humidity"] = values?.is_humidity.toString();
        }
        if (values?.temp_min) {
            payload["temp_min"] = values?.temp_min;
        }
        if (values?.temp_max) {
            payload["temp_max"] = values?.temp_max;
        }
        if (values?.humi_min) {
            payload["humidity_min"] = values?.humi_min;
        }
        if (values?.humi_max) {
            payload["humidity_max"] = values?.humi_max;
        }
        if (values?.co2_min) {
            payload["ppm_min"] = values?.co2_min;
        }
        if (values?.co2_max) {
            payload["ppm_max"] = values?.co2_max;
        }
        if (!isEdit) {
            dispatch(addAlarmData(payload));
        } else {
            dispatch(editAlarmData({ data:payload, id: editAlarm?.id }));
        }
    }



    const closeBtn = (
        <button className="close text-white" onClick={toggle} type="button">
            &times;
        </button>
    );


    const formik = useFormik({
        initialValues: initialValues[module?.device_identifier || ""],
        validationSchema: validationSchema[module?.device_identifier || ""],
        onSubmit: async (values) => {
            handleSubmit(values);
        },
    });


    useEffect(() => {
        if (isEdit) {
            setModule({device_identifier:editAlarm?.device_identifier||""});
            formik.setFieldValue("facility", {value:editAlarm?.facility_id,label:editAlarm?.facility_name});
            formik.setFieldValue("device", {value:editAlarm?.user_device_id,label:editAlarm?.device_name});
            formik.setFieldValue("area", {value:editAlarm?.cultivation_area_id,label:editAlarm?.cultivation_name, isDisabled:false});
            formik.setFieldValue("device_module", {id:editAlarm?.device_module_id,name:editAlarm?.module_name});
            editAlarm?.cards?.map((item, index) => {
                if (item.title === "Temperature") {
                    formik.setFieldValue("temp_min", item?.data?.["min"]);
                    formik.setFieldValue("temp_max", item?.data?.["max"]);
                }
                if (item.title === "Humidity") {
                    formik.setFieldValue("humi_min", item?.data?.["min"]);
                    formik.setFieldValue("humi_max", item?.data?.["max"]);
                }
                if (item.title === "CO2") {
                    formik.setFieldValue("co2_min", item?.data?.["min"]);
                    formik.setFieldValue("co2_max", item?.data?.["max"]);
                }
                if (item.title === "Configurations") {
                    Object.keys(item?.data)?.map((data) => {
                        if (data == "is_temp") {
                            formik.setFieldValue("is_temp", item?.data?.[data]);
                        }
                        if (data == "is_ppm") {
                            formik.setFieldValue("is_ppm", item?.data?.[data]);
                        }
                        if (data == "is_humidity") {
                            formik.setFieldValue("is_humidity", item?.data?.[data]);
                        }
                    });
                }
            })
        }else{
            formik.setFieldValue("is_humidity", true);
            formik.setFieldValue("is_temp", true);
            formik.setFieldValue("is_ppm", true);
        }
    }, [editAlarm]);

    const getAreaList = async (id) => {
        await dispatch(getAreaListData({ id: id }));
    }
    const getDeviceList = async (id) => {
        await dispatch(getDeviceListData({ facility_id: id }));
    }

    const handleSelectArea = (value) => {
        if (value) {
            const module = area.filter((item) => {
                return item.cultivation_area.value === value.value;
            });
            formik.setFieldValue("device_module", module?.[0].device_module || null);
        }
    }

    const handleMinMaxDisabled = (name) => {
        if(name==="temp_min"||name==="temp_max"){
            if(formik?.values?.is_temp==true){
                return false;
            }else{
                return true;
            }
        }
        if(name==="co2_min"||name==="co2_max"){
            if(formik?.values?.is_ppm==true){
                return false;
            }else{
                return true;
            }
        }
        if(name==="humi_min"||name==="humi_max"){
            if(formik?.values?.is_humidity==true){
                return false;
            }else{
                return true;
            }
        }
    }
    const handleCheck = (name,isCheck) => {
        if(name==="is_temp"){
            if(isCheck){
                formik.setFieldValue("is_temp",isCheck);
            }else{
                formik.setFieldValue("is_temp",isCheck);
                // formik.setFieldValue("temp_min",""); ///   
                // formik.setFieldValue("temp_max",""); ///   
            }
        }
        if(name==="is_ppm"){
            if(isCheck){
                formik.setFieldValue("is_ppm",isCheck);
            }else{
                formik.setFieldValue("is_ppm",isCheck);
                // formik.setFieldValue("co2_min",""); ///  
                // formik.setFieldValue("co2_max",""); ///    
            }
        }
        if(name==="is_humidity"){
            if(isCheck){
                formik.setFieldValue("is_humidity",isCheck);
            }else{
                formik.setFieldValue("is_humidity",isCheck);
                // formik.setFieldValue("humi_min",""); ///   
                // formik.setFieldValue("humi_max",""); ///   
            }
        }
        
    }


const handleChangeFacility = (e) => {
    if (formik.values?.facility?.value !== e?.value) {
        formik.setFieldValue("facility", e);
        setModule({ device_identifier: "" });
        formik.setFieldValue("area", null);
        formik.setFieldValue("device", null);
        formik.setFieldValue("device_module", null);
        getAreaList(e.value);
        getDeviceList(e.value);
    }
}

const handleChangeDevice=(value) => {
    if (formik.values?.device?.value !== value?.value) {
        formik.setFieldValue('device', { value: value.value, label: value.label });
        setModule({ device_identifier: value.device_identifier });
        formik.setFieldValue("area", null);
        dispatch(getDevicePairingsData({ id: value.value }));
    }
}


    return (
        <>
            <Modal returnFocusAfterClose={true} isOpen={open} backdrop={true} size='lg' className="facility-modal">
                <form onSubmit={async (e) => {
                    e.preventDefault();
                    const errors = await formik.validateForm();
                    if (Object.keys(errors).length) {
                        const touchedFields = {};
                        Object.keys(errors).forEach((key) => {
                            touchedFields[key] = true;
                        });
                        formik.setTouched(touchedFields);
                    } else {
                        formik.handleSubmit(e);
                    }
                }}>
                    <ModalHeader className="facility-modal-header text-white w-100" close={closeBtn}>
                        <p className="pr-2 mb-3 h4 text-white text-capitalize">
                            {isEdit ? 'Edit Alarm' : 'Add New Alarm'}
                        </p>
                    </ModalHeader>
                    <ModalBody className="facility-modal-body">
                        <Row>
                            <Col sm={12} lg={6}>
                                <FormGroup className={formik.errors.name && formik.touched.name ? 'has-error' : ''}>
                                    <Label for="facility" className="mb-0">Facility</Label>
                                    <Select
                                        id="facility"
                                        name="facility"
                                        type='select'
                                        className="bg-dark basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        isDisabled={isEdit}
                                        onBlur={e => formik.setFieldTouched("facility", true)}
                                        value={formik.values.facility}
                                        options={[...facilityList.List?.map(facility => ({ value: facility?.id, label: facility?.facility_name }))]}
                                        onChange={(e) => {handleChangeFacility(e);}}
                                        />
                                    {formik.errors.facility && formik.touched.facility ? (
                                        <div className="invalid-feedback d-block">{formik.errors.facility}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormGroup className={formik.errors.device && formik.touched.device ? 'has-error' : ''}>
                                    <Label for="device" className="mb-0">Device</Label>
                                    <Select
                                        id="device"
                                        name="device"
                                        type="select"
                                        className="bg-dark basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        isDisabled={isEdit}
                                        onBlur={e => formik.setFieldTouched("device", true)}
                                        invalid={formik.errors.device && formik.touched.device}
                                        value={formik.values.device}
                                        options={[...deviceList.List?.map((item, index) => ({ value: item?.id, label: item?.device_name, device_identifier: item.device_identifier }))]}
                                        onChange={(value) => {handleChangeDevice(value); }}
                                    // {...getFieldProps('device')}
                                    />
                                    {formik.errors.device && formik.touched.device ? (
                                        <div className="invalid-feedback d-block">{formik.errors.device}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormGroup className={formik.errors.area && formik.touched.area ? 'has-error' : ''}>
                                    <Label for="area" className="mb-0">Area</Label>
                                    <Select
                                        id="area"
                                        name="area"
                                        type='select'
                                        className="bg-dark basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        isDisabled={isEdit}
                                        onBlur={e => formik.setFieldTouched("area", true)}
                                        value={formik.values.area || null}
                                        invalid={formik.errors.area && formik.touched.area}
                                        options={[...area?.map(area => (area?.cultivation_area))]}
                                        onChange={(e) => {
                                            formik.setFieldValue("area", e);
                                            handleSelectArea(e);
                                        }}
                                    />
                                    {formik.errors.area && formik.touched.area ? (
                                        <div className="invalid-feedback d-block">{formik.errors.area}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col sm={12} lg={6}>
                                <FormGroup >
                                    <Label for='device_module' className="mb-0">Device Module</Label>
                                    <Input
                                        id="device_module"
                                        name='device_module'
                                        type="text"
                                        className="bg-dark text-white"
                                        invalid={formik?.errors?.device_module && (formik?.touched?.device_module || false)}
                                        onBlur={e => formik.setFieldTouched("device_module", true)}
                                        value={formik?.values?.device_module?.name || ""}
                                        disabled={true}
                                    // onChange={e=>{return;}}
                                    />
                                    {formik.errors.area && formik.touched.area ? (
                                        <div className="invalid-feedback d-block">{formik.errors.area}</div>
                                    ) : null}
                                </FormGroup >
                            </Col>
                            {
                                inputsData[module?.device_identifier || ""].map((item, index) => {

                                    return (
                                        <>
                                            <Col sm={12} lg={!item.isInput ? 12 : item.type === "checkbox" ? 4 : 6} key={index}>
                                                {!item.isInput
                                                    ? (<Label for={item.id} className="mb-1 mt-2">{item?.label}</Label>)
                                                    : item.type === "checkbox"
                                                        ? (<FormGroup >
                                                            <CustomInput
                                                                type="switch"
                                                                id={item?.name}
                                                                name={item?.name}
                                                                defaultValue={true}
                                                                label={item?.label}
                                                                onBlur={formik?.handleBlur}
                                                                checked={formik.values[item?.name]}
                                                                onChange={e => handleCheck(item.name,e.target.checked)}
                                                            />
                                                            {formik?.errors[item?.name] && formik?.touched[item?.name] ? <FormFeedback invalid className='d-flex mt-0'> {formik?.errors[item?.name]}</FormFeedback> : null}
                                                           
                                                        </FormGroup>)
                                                        : (<FormGroup>
                                                            <Label for={item.id} className="mb-0">{item?.label}</Label>
                                                            {item?.InputGroupText ? <>
                                                                <InputGroup className='mb-0'>
                                                                    <InputGroupText className='bg-dark border border-bottom-0 border-left-0 border-top-0 border-darker border-default'>
                                                                        {item?.InputGroupText}
                                                                    </InputGroupText>
                                                                    <Input
                                                                        id={item?.id}
                                                                        name={item?.name}
                                                                        type={item?.type}
                                                                        disabled={handleMinMaxDisabled(item?.name)}
                                                                        className="bg-dark text-white"
                                                                        invalid={formik?.errors[item?.name] && (formik?.touched[item?.name] || false)}
                                                                        onBlur={(e) => {
                                                                            formik?.handleBlur(e);
                                                                        }}
                                                                        value={formik?.values[item?.name]}
                                                                        onChange={(e) => {
                                                                            formik?.handleChange(e);
                                                                        }} />
                                                                </InputGroup>
                                                                {formik?.errors[item?.name] && (formik?.touched[item?.name] || false) ? <FormFeedback invalid className='d-flex mt-0'> {formik?.errors[item?.name]}</FormFeedback> : null}
                                                            </>
                                                                :
                                                                <>
                                                                    <Input
                                                                        id={item?.id}
                                                                        name={item?.name}
                                                                        type={item?.type}
                                                                        className="bg-dark text-white"
                                                                        invalid={formik?.errors[item?.name] && formik?.touched[item?.name]}
                                                                        onBlur={(e) => {
                                                                            formik?.handleBlur(e);
                                                                        }}
                                                                        value={formik?.values[item?.name]}
                                                                        onChange={(e) => {
                                                                            formik?.handleChange(e);
                                                                        }} />
                                                                    {formik?.errors[item?.name] && formik?.touched[item?.name] ? <FormFeedback invalid className='d-flex mt-0'> {formik?.errors[item?.name]}</FormFeedback> : null}
                                                                </>
                                                            }

                                                        </FormGroup>)}
                                            </Col>
                                        </>
                                    );
                                })}
                        </Row>
                    </ModalBody>
                    <ModalFooter className="facility-modal-footer">
                        <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button" onClick={toggle}>
                            Close
                        </Button>
                        <Button className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="submit"
                            // onClick={handleSubmit}
                            disabled={addAlarm.loading === "loading" || EditAlarm.loading === "loading"}
                        >
                            {
                                (addAlarm.loading === "loading" || EditAlarm.loading === "loading")
                                    ? <Spinner size="sm">{isEdit ? "Saving" : "Creating"}</Spinner> :
                                    <>{isEdit ? "Save" : "Create "}</>
                            }
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddAlarmModel