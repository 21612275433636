import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_FACILITY_LIST } from 'utils/utils';

export const getFacilityListData = createAsyncThunk(
  'facilityList/getFacilityListData',
  async (_,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: GET_FACILITY_LIST,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  List: [],
  loading: 'idle',
  error: null,
};

export const facilityListSlice = createSlice({
  name: 'facilityList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFacilityListData.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(getFacilityListData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.List = action.payload?.data?.data
          state.error = null
      })
      .addCase(getFacilityListData.rejected, (state, action) => {
          state.loading = 'failed'
          state.List = []
          state.error = action.payload
      })
  },
})


export default facilityListSlice.reducer;