
import { faArchway, faMapLocationDot, faPenToSquare, faTrashCan, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import NotificationAlert from "react-notification-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Collapse, Progress, Row, Spinner } from 'reactstrap'
import ReactBSAlert from "react-bootstrap-sweetalert";
import FacilityAddAndEdit from './components/AllModals/FacilityAddAndEdit';
import { useDispatch, useSelector } from 'react-redux';
import { getFacilityTableData } from 'features/facility/facilityTableSlice';
import { deleteFacilityData } from 'features/facility/deleteFacility';
import PaginationNav from './components/PaginationNav';
import { deleteFacilityRemoveStateData } from 'features/facility/deleteFacility';

const Facility = () => {
  const [openedCollapseOne, setOpenedCollapseOne] = React.useState([
    false, false, false, false, false, false, false, false, false, false,
  ]);
  const [alert, setAlert] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editFacility, setEditFacility] = React.useState(false);
  const notificationAlertRef = React.useRef(null);

  const tableData = useSelector((state) => state.facilityTable);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getFacilityTableData({ page: 1 }))
  }, [])

  const refScrollUp = useRef(null);


  const handleCollapse = (index) => {
    setOpenedCollapseOne([...openedCollapseOne.slice(0, index), !openedCollapseOne[index], ...openedCollapseOne.slice(index + 1)]);
  }
  const facilityDelete = useSelector((state) => state.deleteFacility);

  const handleDeleteFacility = async (facility) => {
    // API call
    hideAlert();
    await dispatch(await deleteFacilityData({ ...facility }));
    await dispatch(await getFacilityTableData({ page: 1 }));
  }


  React.useEffect(() => {
    if (facilityDelete.loading === "succeeded") {
      successDelete();
      setLoading(false);
      dispatch(deleteFacilityRemoveStateData());
    }
    if (facilityDelete.loading === "failed") {
      hideAlert();
      notify(facilityDelete?.error?.data?.message, true);
      setLoading(false);
      dispatch(deleteFacilityRemoveStateData());
    }
    if (facilityDelete.loading === "loading") {
      setLoading(true);
    }
  }, [facilityDelete.loading]);

  React.useEffect(() => {

    if (facilityDelete.loading === "loading") {
      setLoading(true);
    }
    if (facilityDelete.loading === "failed") {
      notify(facilityDelete?.error?.data?.message, true);
      setLoading(false);
    }
    window.scrollTo(0, 0);

  }, [tableData.loading]);

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Facility hasa been deleted.
      </ReactBSAlert>
    );
  };


  const warningWithConfirmMessage = (facility) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteFacility(facility)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this facility!
      </ReactBSAlert>
    );
  };

  const notify = (msg,isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError?"danger":"success",
      icon:isError? "tim-icons icon-alert-circle-exc":"tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  const hideAlert = () => {
    setAlert(null);
  };

  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);


  const handleOpenEditModal = (facility) => {
    setIsEdit(true);
    setEditFacility(facility);
    toggle();
  }

  const handleOpenAddModal = () => {
    setIsEdit(false);
    setEditFacility(null);
    toggle();
  }

  const handlePageChange = async (page) => {
    await dispatch(getFacilityTableData({ page }));
  }


  return (
    <>
      { }
      <div className='content ' id='facilityTable' >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {alert}
        <Card ref={refScrollUp}>
          <CardHeader headerTag="h6" className="bg-transparent border-bottom-0">
            <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
              <div className="numbers">
                <p className=" px-2 mb-0 h4 text-white text-capitalize">existing Facility</p>
              </div>
              <Button className="btn-simple border-white text-white d-flex add-btn justify-content-center align-items-center text-capitalize" color="primary" type="button" onClick={handleOpenAddModal}>
                <i className="tim-icons icon-simple-add pr-2" />Add New Facility
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {
              tableData?.page_Data?.map((item, index) =>
              (
                <>
                  <Card className="facility-card" key={item.name + index + "Card"}>
                    <CardHeader>
                      <Row>
                        <Col md={12} lg={4}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-3 d-flex justify-content-start align-items-center w-fit">
                              <FontAwesomeIcon icon={faWarehouse} size="xs" className='mb-1' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-2 font-weight-normal"> Facility Name</p>
                            </div>
                            <div
                              role="button"
                              aria-expanded={openedCollapseOne}
                              href="#pablo"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              className='d-flex justify-content-start align-items-center w-fit pointer-event'
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenedCollapseOne(!openedCollapseOne[index]);
                                handleCollapse(index);
                              }}
                            >
                              {openedCollapseOne[index]
                                ? <i className="tim-icons icon-minimal-down pl-2 fa-rotate-180 animation-transition-general d-none d-lg-block d-md-none" />
                                : <i className="tim-icons icon-minimal-down pr-2 animation-transition-general d-none d-lg-block d-md-none" />
                              }
                              <span className=" mb-0 text-white-50 fs-2 font-weight-light ">{item?.facility_name}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} lg={3}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-3 d-flex justify-content-start align-items-center w-fit ">
                              <FontAwesomeIcon icon={faMapLocationDot} size="sm" className='mb-1' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">Location</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">{item?.city + ", " + item?.country}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} lg={3}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-3 d-flex justify-content-start align-items-center w-fit ">
                              <FontAwesomeIcon icon={faArchway} size="sm" className='mb-1' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">Cultivation Unit</p>
                            </div>
                            <div className="text-truncate font-weight-normal fs-3 mb-0 mt-1 px-2 text-white position-relative w-100">
                              <Progress max={item?.total_area} value={item?.cultivation_areas.length} color='dark' className='progress-bar-facility m-0'>
                                <span className="progress-value position-absolute progress-value-facility h5">{item?.cultivation_areas?.length}/{item?.total_area}</span>
                              </Progress>
                            </div>
                          </div>
                        </Col>
                        <Col md={12} lg={2}>
                          <div className="d-none d-lg-flex justify-content-around align-items-center h-100" >
                            <FontAwesomeIcon icon={faPenToSquare} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={(e) => { handleOpenEditModal(item) }} />
                            <FontAwesomeIcon icon={faTrashCan} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={() => warningWithConfirmMessage(item)} />
                          </div>
                        </Col>
                        <Col md={12} lg={0} className='d-lg-none'>
                          <dov className="d-flex w-100 justify-content-between align-items-center mb-1">
                            <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenedCollapseOne(!openedCollapseOne[index]);
                                handleCollapse(index);
                              }}>
                              {openedCollapseOne[index]
                                ? <i className="tim-icons icon-minimal-down px-2 fa-rotate-180 animation-transition-general d-block d-lg-none mb-1" />
                                : <i className="tim-icons icon-minimal-down px-2 animation-transition-general d-block d-lg-none " />
                              }
                              Areas
                            </Button>
                            <div className="d-flex justify-content-around align-items-center" >
                              <FontAwesomeIcon icon={faPenToSquare} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={(e) => { handleOpenEditModal(item) }} />
                              <FontAwesomeIcon icon={faTrashCan} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={() => warningWithConfirmMessage(item)} />
                            </div>
                          </dov>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className='pb-0 px-0'>
                      <Collapse role="tabpanel" isOpen={openedCollapseOne[index]}>
                        <div className='p-3 d-flex flex-wrap facility-collapse-div'>
                          {
                            item?.cultivation_areas.map((unit, Index) => (
                              <div className="mx-1 my-1 text-white-50 text-capitalize " key={"cultivation_name" + Index}> <kbd className='px-2 py-1'>{unit?.cultivation_name}</kbd></div>
                            ))
                          }
                        </div>
                      </Collapse>
                    </CardBody>
                  </Card>
                </>
              )
              )
            }

            <>
              {(tableData.page_Data && tableData.page_Data.length > 0) ?
                tableData.page_information?.last_page > 1 ? <PaginationNav page_information={tableData?.page_information} setPage={handlePageChange} /> : <></>
                : <p className='h5'>There no existing facilities here, press "Add new facility" to create first one.</p>
              }
            </>
          </CardBody>

        </Card>
      </div>
      {open ?
        <FacilityAddAndEdit
          open={open}
          toggle={toggle}
          isEdit={isEdit}
          editFacility={editFacility}
          notify={notify}
        /> : <></>
      }
      {(facilityDelete.loading === "loading" || tableData?.loading === "loading") &&
        <div className="loading-container">
          <div className="d-flex justify-content-center loading-div"><Spinner size="xl" /></div>
        </div>
      }
    </>
  )
}

export default Facility

