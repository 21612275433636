
import * as Yup from 'yup';

export const initialValues = {
    "": {
        profile_name: "",
        user_device_id: "",
    },
    "0": {
        profile_name: "",
        user_device_id: "",
    },
    "1": {
        profile_name: "",
        user_device_id: "",
        temp_ir: false,
        co2_light: true,
        light_dynamic: false,
        temp_day: 25,
        temp_night: 20,
        temp_period: 30,
        humi_day: 50,
        humi_night: 80,
        humi_period: 30,
        co2_day: 1000,
        co2_night: 0,
        co2_period: 30,
        light_start: "06:00",
        light_stop: "14:00",
        light_on: 100,
        light_off: 0,
        light_ppfd: 800,
    },
}

export const validationSchema = {

    "": Yup.object().shape({
        profile_name: Yup.string().required("profile_name is Required"),
        user_device_id: Yup.string().required("device is Required"),
    }),
    "0": Yup.object().shape({
        profile_name: Yup.string().required("profile_name is Required"),
        user_device_id: Yup.string().required("device is Required"),
    }),
    "1": Yup.object().shape({
        profile_name: Yup.string().required("profile_name is Required"),
        user_device_id: Yup.string().required("device is Required"),
        temp_ir: Yup.boolean().required("Temperature ir is Required"),
        co2_light: Yup.boolean().required("co2 light is Required"),
        light_dynamic: Yup.boolean().required("light dynamic is Required"),
        temp_day: Yup.number().required("Day Temperature is Required").min(10, "minimum value is 10").max(40, "maximum value is 40"),
        temp_night: Yup.number().required("Night Temperature is Required").min(10, "minimum value is 10").max(40, "maximum value is 40"),
        temp_period: Yup.number().required("Period Temperature is Required").min(0, "minimum value is 0").max(120, "maximum value is 120"),
        humi_day: Yup.number().required("Day Humidity is Required").min(20, "minimum value is 20").max(100, "maximum value is 100"),
        humi_night: Yup.number().required("Night Humidity is Required").min(20, "minimum value is 20").max(100, "maximum value is 100"),
        humi_period: Yup.number().required("Period Humidity is Required").min(0, "minimum value is 0").max(120, "maximum value is 120"),
        co2_day: Yup.number().required("Day co2 is Required").min(0, "minimum value is 0").max(3500, "maximum value is 3500"),
        co2_night: Yup.number().required("Night co2 is Required").min(0, "minimum value is 0").max(3500, "maximum value is 3500"),
        co2_period: Yup.number().required("Period co2 is Required").min(0, "minimum value is 0").max(120, "maximum value is 120"),
        light_start: Yup.string().required("light start is Required"),
        light_stop: Yup.string().required("light stop is Required"),
        light_on: Yup.number().required("light on is Required").min(0, "minimum value is 0").max(100, "maximum value is 100"),
        light_off: Yup.number().required("light off is Required")
            .when("light_on", (light_on, schema) =>
                light_on !== undefined
                    ? schema.max(light_on - 1, "light off must be less than light on")
                    : schema
            ).max(100, "maximum value is 100").min(0, "minimum value is 0"),
        light_ppfd: Yup.number().required("light ppfd is Required").min(0, "minimum value is 0").max(2000, "maximum value is 2000"),
    }),
}

export const inputsData = {
    "": [
        { name: "profile_name", label: "Profile Name", type: "text", key: "profile_name", id: "profile_name", InputGroupText: "", isInput: true },
        // { name: "user_device_id", label: "Device Id", type: "text", key: "user_device_id", id: "user_device_id", InputGroupText:"", isInput:true },
    ],
    "0": [
        { name: "profile_name", label: "Profile Name", type: "text", key: "profile_name", id: "profile_name", InputGroupText: "", isInput: true },
        // { name: "user_device_id", label: "Device Id", type: "text", key: "user_device_id", id: "user_device_id", InputGroupText:"", isInput:true },
    ],
    "1": [
        { name: "profile_name", label: "Profile Name", type: "text", key: "profile_name", id: "profile_name", InputGroupText: "", isInput: true },
        // { name: "user_device_id", label: "Device Id", type: "text", key: "user_device_id", id: "user_device_id", InputGroupText:"", isInput:true },
        { label: " ", isInput: false },
        { name: "temp_ir", label: "Temperature IR", type: "checkbox", key: "temp_ir", id: "temp_ir", InputGroupText: "", isInput: true },
        { name: "co2_light", label: <> Co<sub>2</sub> Light</>, type: "checkbox", key: "co2_light", id: "co2_light", InputGroupText: "", isInput: true },
        { name: "light_dynamic", label: "Light Dynamic", type: "checkbox", key: "light_dynamic", id: "light_dynamic", InputGroupText: "", isInput: true },
        { label: " ", isInput: false },
        { name: "temp_day", label: "Day Temperature", type: "number", key: "temp_day", id: "temp_day", InputGroupText: <>&deg;C</>, isInput: true },
        { name: "temp_night", label: "Night Temperature", type: "number", key: "temp_night", id: "temp_night", InputGroupText: <>&deg;C</>, isInput: true },
        { name: "temp_period", label: "Period Temperature", type: "number", key: "temp_period", id: "temp_period", InputGroupText: <>Min</>, isInput: true },
        { label: " ", isInput: false },
        { name: "humi_day", label: "Day Humidity", type: "number", key: "humi_day", id: "humi_day", InputGroupText: <>%</>, isInput: true },
        { name: "humi_night", label: "Night Humidity", type: "number", key: "humi_night", id: "humi_night", InputGroupText: <>%</>, isInput: true },
        { name: "humi_period", label: "Period Humidity", type: "number", key: "humi_period", id: "humi_period", InputGroupText: <>Min</>, isInput: true },
        { label: " ", isInput: false },
        { name: "co2_day", label: <>Day Co<sub>2</sub> </>, type: "number", key: "co2_day", id: "co2_day", InputGroupText: <>PPM</>, isInput: true },
        { name: "co2_night", label: <>Night Co<sub>2</sub> </>, type: "number", key: "co2_night", id: "co2_night", InputGroupText: <>PPM</>, isInput: true },
        { name: "co2_period", label: <>Period Co<sub>2</sub> </>, type: "number", key: "co2_period", id: "co2_period", InputGroupText: <>Min</>, isInput: true },
        { label: " ", isInput: false },
        { name: "light_start", label: "Light Start", type: "time", key: "light_start", id: "light_start", InputGroupText: "", isInput: true },
        { name: "light_stop", label: "Light End", type: "time", key: "light_stop", id: "light_stop", InputGroupText: "", isInput: true },
        { name: "light_on", label: "Light On", type: "number", key: "light_on", id: "light_on", InputGroupText: <>%</>, isInput: true },
        { name: "light_off", label: "Light Off", type: "number", key: "light_off", id: "light_off", InputGroupText: <>%</>, isInput: true },
        { name: "light_ppfd", label: "Light PPFD", type: "number", key: "light_ppfd", id: "light_ppfd", InputGroupText: "", isInput: true },
    ],
}