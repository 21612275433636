import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";

const Logs = () => {


  const notificationAlertRef = React.useRef(null);
  const refScrollUp = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openPair, setOpenPair] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("activity");

  const toggle = () => {
    setOpen(!open)
  };

  const togglePair = () => {
    setOpenPair(!openPair)
  };



  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Device hasa been deleted.
      </ReactBSAlert>
    );
  };


  const warningWithConfirmMessage = (device) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteDevice(device)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this device!
      </ReactBSAlert>
    );
  };

  const handleDeleteDevice = async (device) => {
    // API call
    hideAlert();
  }

  const notify = (msg, isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError ? "danger" : "success",
      icon: isError ? "tim-icons icon-alert-circle-exc" : "tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  const hideAlert = () => {
    setAlert(null);
  };


  const handleOpenEditModal = async (device) => {
    setIsEdit(true);
    toggle();
  }

  const handleOpenAddModal = async () => {
    setIsEdit(false);
    toggle();
  }

  const changeActiveTab = (name) => {
    setActiveTab(name);
  }

  return (
    <>
      { }
      <div className='content ' id='facilityTable' >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {alert}
        <Row className="d-flex justify-content-between align-items-center border-bottom border-dark position-sticky facility-modal-tab-row mx-1 bg-transparent">

          <Col
            className={activeTab === 'activity' ? 'active-facility-modal-tab facility-modal-tab tab-title' : 'inactive-facility-modal-tab facility-modal-tab tab-title'}
            onClick={(e) => changeActiveTab('activity')}
          >
            Activity Logs
          </Col>
          <Col
            className={activeTab === 'streaming' ? 'active-facility-modal-tab facility-modal-tab tab-title' : 'inactive-facility-modal-tab facility-modal-tab tab-title'}
            onClick={(e) => changeActiveTab('streaming')}
          >
            Streaming Data
          </Col>

        </Row>
        <Card ref={refScrollUp}>

          <CardHeader headerTag="h6" className="bg-transparent border-bottom-0">
            <CardBody>
              <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
                <div className="numbers">
                  <p className=" px-2 mb-0 h4 text-white text-capitalize">{activeTab === 'streaming'?"Streaming Data":"Activity Logs"}</p>
                </div>
                <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button" onClick={handleOpenAddModal}>
                  <i className="tim-icons icon-cloud-download-93 pr-2" />Download as CSV
                </Button>
              </div>
            </CardBody>
          </CardHeader>
          <CardBody>
            {activeTab === 'streaming'
              ? (<Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th className="text-center">Id</th>
                    <th className="text-center">Device id</th>
                    <th className="text-center">Module</th>
                    <th className="text-center">Room temp</th>
                    <th className="text-center">Leaf temp</th>
                    <th className="text-center">Humi</th>
                    <th className="text-center">CO2</th>
                    <th className="text-center">VPD</th>
                    <th className="text-center">Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                </tbody>
              </Table>)
              : (<Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th className="text-center">Id</th>
                    <th className="text-center">Device id</th>
                    <th>Loge msg</th>
                    <th className="text-center">Log lv</th>
                    <th className="text-center">Create data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                  <tr>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                    <td className="text-center">Call</td>
                  </tr>
                </tbody>
              </Table>)}
          </CardBody>
        </Card>

      </div>
    </>
  )
}

export default Logs