import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_PROFILE } from 'utils/utils';

export const getUserData = createAsyncThunk(
  'userData/getUserData',
  async (_,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");
    const config = {
      method: "get",
      url: GET_PROFILE,
      headers: {
        Authorization: token,
      },
    };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
)

const initialState = {
  userData: null,
  loading: 'idle',
  error: null,
}

export const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(getUserData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.userData = action.payload.data
          state.error = null
      })
      .addCase(getUserData.rejected, (state, action) => {
          state.loading = 'failed'
          state.userData = null
          state.error = action.payload
      })
  },
})


export default userSlice.reducer;